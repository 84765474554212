.learning_trajectories {
    position: relative;
    width: 100%;
    background-color: transparent;
    height: 825px;
    border: 2px solid transparent;
    border-image: linear-gradient(40deg, #0b75bbff, #d02625ff);
    border-image-slice: 1;
    margin-bottom: 2rem;
    border-radius: 10px;

    .my_personal_trajectory {
        right: 0;
        bottom: 0;
        position: absolute;
        padding: 1rem;
        max-width: 50%;
        text-align: center;
        
    }
    .my_personal_trajectory:hover {
        cursor: pointer;
    }

    #customize_selection_dialog[open] {
        display: flex;
        flex-direction: column;
        max-width: 800px;
        width: 100%;
        border: none;
        border-radius: 5px;

        div {
            visibility: inherit;
        }
        .alternatives {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: stretch;
            .alternative {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                padding: 1rem;
                border: 1px solid black;
                border-radius: 5px;
                margin: 0.5rem;
                cursor: pointer;
                &:hover {
                    background-color: #F6FAF2;
                }
                .image {
                    width: 80px;
                    height: 80px;
                    object-fit: cover;
                    margin-right: 1rem;
                }
                .alternative_content {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: stretch;
                    .title {
                        font-size: 1.5rem;
                    }
                }
                
            }
        }
    }
    #customize_selection_dialog:not([open]) {
        display: none;
        div {
            visibility: hidden;
        }
    }
    #customize_selection_dialog::backdrop {
        background-color: rgba(0, 0, 0, 0.5);
    }

    .ai_triangle {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-color: #0b75bbff;
        clip-path: polygon(0% 75%, 50% 100%, 0% 100%);

        .ai_logo {
            position: absolute;
            bottom: 1rem;
            left: 1.5rem;
            max-width: 20%;
            max-height: 18%;
            color: white;
        }
    }

    .pc_triangle {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-color: #d02625ff;
        clip-path: polygon(25% 0%, 100% 0%, 100% 25%);
        /*TODO: replace with PC logo -> copy settings from AI logo*/
        .pc_logo {
            position: absolute;
            top: 1rem;
            right: 1.5rem;
            font-size: 2.5rem;
            font-weight: bold;
            color: white;
        }
    }

    .lines {
        position: absolute;
        top: 20%;
        left: 5%;
        bottom: 15%;
        right: 5%;
        overflow-x: auto;
        white-space: nowrap;

        .lines_content {
            position: absolute;
            min-width: 1000px;
            width: 100%;
            height: 100%;
        }

        
        .pc_line {
            background-color: #f9e1e1ff;
            position: absolute;
            top: 3.3rem;
            left: 0;
            right: 0;
            height: 42.5%;
        }

        .ai_line {
            background-color: #e6edf4ff;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            height: 42.5%;
        }

    }

    .grades {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: stretch;


        .grade {
            top: 0;
            bottom: 0;
            width: 30%;
            border: 3px dashed black;
            position: relative;
            .title {
                font-size: 2rem;
                width: 100%;
                text-align: center;
            }
            .grade1 {
                
            }
            .grade2 {

            }
            .grade3 {

            }
            .line_item {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                .choose_alternative {
                    padding-top: 1rem;
                    text-decoration: underline;
                    cursor: pointer;
                }
                .image {
                    /* zoom in on image on hover*/
                    object-fit: cover;
                    transition: transform 0.5s;
                    cursor: pointer;
                    &:hover {
                        transform: scale(1.2);
                    }
                }
                .title {
                    cursor: pointer;
                }
            }
            .ai_line_item {
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                height: 42.5%;
            }
            .pc_line_item {
                position: absolute;
                top: 3.3rem;
                left: 0;
                right: 0;
                height: 42.5%;
            }
        }

        .first_grade {
        }
        .second_grade {
           
        }
        .third_grade {
             
        }
    }
}


